export const endpointsCreator = (...args: string[]) => {
	const endpoints = {
		USER: {
			/**
			 * @method: POST
			 * */
			LOGIN: '/login',
			RESET_PASSWORD: '/users/resetPassword'
		},
		SECRETARY: {
			/**
			 * @method: POST
			 * */
			POST_SECRETARY_CREATION: '/secretaries',

			/**
			 * @method: GET
			 *  args[0]: secretaryCpf
			 * */
			CREATE_SECRETARY_PASSWORD: `/secretaries/${args[0]}/confirm`,

			/**
			 * @method: PUT
			 *  args[0]: secretaryCpf
			 * */
			PUT_SECRETARY_CONFIRM: `/secretaries/${args[0]}/confirm`,

			/**
			 * @method: POST
			 *  args[0]: id
			 * */
			POST_SECRETARY_CONFIRM: `/secretaries/${args[0]}/confirm`,

			/**
			 * @method: PUT
			 * */
			UPDATE_SECRETARY: '/secretaries',

			/**
			 * @method: PUT
			 * args[0]: id
			 * */
			REACTIVATE_SECRETARY: `/secretaries/${args[0]}/reactivate`,

			/**
			 * @method: PUT
			 * args[0]: doctorCpf
			 * args[1]: id
			 * */
			DOCTOR_UPDATE_SECRETARIES: `/doctors/${args[0]}/secretaries/${args[1]}`,

			/**
			 * @method: GET
			 * args[0]: id
			 * */
			GET_DOCTOR_SECRETARIES: `/doctors/${args[0]}/secretaries`,

			/**
			 * @method: GET
			 * args[0]: secretaryCpf
			 * */
			GET_SECRETARY: `/secretaries/${args[0]}`,

			/**
			 * @method: GET
			 * args[0]: doctorCpf
			 * args[1]: id
			 * */
			GET_SECRETARIES_DOCTOR: `/doctors/${args[0]}/secretaries/${args[1]}`,

			/**
			 * @method: GET
			 * args[0]: secretaryCpf
			 * */
			GET_SECRETARY_DOCTORS: `/secretaries/${args[0]}/doctors`,

			/**
			 * @method: DELETE
			 * args[0]: id
			 * */
			DELETE_SECRETARY: `/secretaries/${args[0]}`
		},
		DOCTOR: {
			/**
			 * @method: GET
			 *  args[0]: doctorCpf
			 * Authorization:{tokenMedico}
			 * */
			GET_DOCTOR_INFO: `/doctors/${args[0]}`,
			/**
			 * @method: GET
			 *  args[0]: doctorCpf
			 * */
			GET_PATIENT_DOCTOR: `/doctors/${args[0]}/patients`,
			/**
			 * @method: PUT
			 * */
			PUT_UPDATE_DOCTOR: '/doctors'
		},
		VALID_CERTIFICATION: {
			/**
			 * @method: GET
			 *  args[0]: doctorCpf
			 * */
			GET_QRCODE: `/certificates/qrCode/${args[0]}`,
			/**
			 * @method: GET
			 *  args[0]: doctorCpf
			 * */
			GET_CERTIFICATES: `/certificates/hasCertificate?userCpfCnpj=CPF&valCpfCnpj=${args[0]}`,
			/**
			 * @method: POST
			 * */
			POST_TOKEN: '/certificates/oauth/token',
			/**
			 * @method: PUT
			 * Authorization:{tokenMedico}
			 * Body: {
			 *     "optins":[...options,"NAORECEBERMAISAPERGUNTA"]
			 * }
			 * */
			PUT_NOT_PERTURB_AD_CERT_CLOUD: '/doctors/optins',
			/**
			 * @method: GET
			 *  args[0]: doctorCpf
			 * */
			GET_PUSH_VALID: `/certificates/push/${args[0]}`,
			POST_CHECK_PUSH_VALID: '/certificates/authentication',
			/**
			 * @method: POST
			 * */
			POST_GENERATE_CERTIFICATION_TICKET: '/certificates/ticket',
			/**
			 * @method: POST
			 * Authorization:{tokenMedico}
			 * Body {
			 * 	"encounterId":11844779,
			 * 	"token":"Bearer 31a2d970-a6af-4468-a46e-0bb1373cafa5",
			 * 	"type":["prescription","exam"]
			 * }
			 * */
			POST_SIGN: '/appointments/signature',
			POST_SIGN_BRADESCO: '/appointments/signature/bradesco'
		},
		CONSULTATION: {
			/**
			 * @method: PUT
			 * */
			PUT_CONSULTATION_PRONTMED_ID: '/appointments',
			/**
			 * @method: GET
			 * args[0] id do atendimento na prontmed
			 * args[1] docs para baixar (ex. type=exam&type=prescription)
			 * response: Retorna um HTML Caso não possua assinatura, se possuir assinatura, retorna um base64
			 * */
			GET_PDF_CONSULTATION: `/appointments/documents?encounterId=${args[0]}&${args[1]}`,

			/**
			 * @method: GET
			 * args[0] id consultation id
			 * args[1] type
			 * */
			DOWNLOAD_PDF: `/appointments/documents?encounterId=${args[0]}&type=${args[1]}`,

			/**
			 * @method: GET
			 * */
			GET_PRESCRIPTIONS: `/appointments/documents/filter?doctorCpf=${args[0]}&patientCpf=${args[1]}`,
			/**
			 * @method: GET
			 *  args[0]: prescription id
			 */
			GET_MEDICAL_RECORDS: `/medicalRecords/${args[0]}`,
			/**
			 * @method: POST
			 * BODY: {
			 * 	"appointmentId": "5f10d512bfe65256ac514b48",
			 * 	"medicalEncounterId": 11844779,
			 * 	"patientId": "48761945"
			 * }
			 */
			POST_CREATE_CLOSED_CONSULTATION: '/appointments/encounter',
			POST_CREATE_CLOSED_CONSULTATION_BRADESCO: '/appointments/encounter/bradesco',
			/**
			 * @method: POST
			 * args[0] id do atendimento na prontmed
			 * args[1] docs para enviar (ex. type=exam&type=prescription)
			 * response: Retorna um HTML Caso não possua assinatura, se possuir assinatura, retorna um base64
			 * */
			POST_SEND_PDF_EMAIL: `/appointments/${args[0]}/patients/email?${args[1]}`,
			/**
			 * @method: POST
			 * */
			RECORD_CONTROLLED_SELL: `/medicalRecord/drugSale`,
			/**
			 * @method: POST
			 * args[0] id do atendimento na prontmed
			 * */
			POST_SEND_PDF_LINK_SMS: `/appointments/${args[0]}/patients/sms`
		},
		PATIENT: {
			/**
			 * @method: PUT
			 * Body: {
			 * id,
			 * uf ?,
			 * optins
			 * }
			 */
			PUT_PATIENT: '/patients',
			/**
			 * @method: GET
			 * args[0] id do paciente
			 * */
			GET_PATIENT_BY_ID: `/patients?id=${args[0]}`
		},
		SUPPORT: {
			/**
			 * @method: GET
			 */
			GET_ADVISOR_SCHEDULES: '/schedules',
			/**
			 * @method: POST
			 */
			SCHEDULE_ADVISORY: '/supports',
			/**
			 * @method: GET
			 */
			GET_SUPPORT: `/supports?requester_document=${args[0]}`,
			/**
			 * @method: DELETE
			 * args[0]: id
			 */
			DELETE: `/supports/${args[0]}`
		},
		ELEGIBILITY: {
			CHECK_ELEGIBILITY: '/eligibility/prior',
			/**
			 * @method: POST
			 */
			VALIDATE_ELEGIBILITY: '/eligibility'
		},
		MARKETPLACE: {
			SELLER: {
				/**
				 * @method: POST
				 */
				SELLER_PAYMENT_OPTIONS: `/marketplace/payment-methods`,
				/**
				 * @method: POST
				 */
				POST_SELLER_REGISTRATION: `/marketplace/sellers`,
				/**
				 * @method: POST/GET
				 * @description: POST: Send seller documents
				 * @description: GET: get documents approved?
				 * args[0]: seller's id
				 *
				 */
				SELLER_DOCUMENTS: `/marketplace/sellers/documents`,
				/**
				 * @method: GET
				 * @description: GET: seller approved?
				 */
				SELLER_REGISTRATION_STATUS: `/marketplace/sellers/${args[0]}`,
				/**
				 * @method: GET
				 * args[0]: doctorCpf
				 */
				GET_PAYMENT_METHODS: `/marketplace/payment-methods`,
				/**
				 * @method: POST
				 * body:
				 * {
				 * 	"providerSellerId": "string",
				 * 	"provider": "string",
				 * 	"status": "string",
				 * 	"service": "string"
				 * }
				 */
				POST_PAYMENT_HOOK: `/marketplace/hook/`
			}
		},

		ZOOP: {
			/**
			 * @method: POST
			 */
			POST_TOKEN: `https://api.zoop.ws/v1/marketplaces/${process.env.GATSBY_MKP_ID}/cards/tokens`
		},

		TRANSACTION: {
			/**
			 * @method: PUT
			 */
			PUT_PAYMENT: `/transactions`,

			/**
			 * @method: GET
			 * * args[0]: transactionId
			 */
			GET_TRANSACTION_BY_ID: `/transactions/${args[0]}`,

			/**
			 * @method: GET
			 * arg[0]: page
			 * arg[1]: limit
			 * arg[2]: !isDoctor
			 * arg[3]: doctorCpf
			 */
			GET_TRANSACTIONS: `/transactions?page=${args[0]}&limit=${args[1]}${
				args[2] ? `&doctorCpf=${args[3]}` : ''
			}`,
			/**
			 * @method: PATCH
			 */
			PATCH_TRANSACTION: `/transactions`,
			/**
			 * @method: POST
			 * arg[0]: transactionId
			 */
			POST_RESEND_PAYMENT_LINK: `/transaction/${args[0]}/email`,
			/**
			 * @method: GET
			 */
			GET_BALANCE_RESUME: `/transactions/balance${args[0] ? `?doctorCpf=${args[0]}` : ''}`
		}
	}
	return endpoints
}
